body {
  background-color: #dadada;
}
#root {
  height: 100%
}
#root > #perc.grid {
  height: 100%;
}
.image {
  margin-top: -100px;
}
.column {
  max-width: 450px;
}
